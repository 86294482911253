import axios from "axios";
import { AuthModel, UserModel } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;


export const LOGIN_URL = `${API_URL}/login`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/password/forgot`;
export const RESET_PASSWORD_URL = `${API_URL}/password/change`;

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<any>(LOGIN_URL, {
    username,
    password,
  });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ data:{otpRequest:''} }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

export function resetPassword(email: string, password:string, otp:string, otpRequest:number) {
  return axios.post<{ data:{otpRequest:''} }>(RESET_PASSWORD_URL, {
    email,
    password,
    otp,
    otpRequest
  });
}

export function changePassword(currentPassword: string, newPassword:string) {
  return axios.put<{ data:{otpRequest:''} }>(RESET_PASSWORD_URL, {
    currentPassword,
    newPassword
  });
}

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/users/profile`;
export const GET_USER_ACCESS_URL = `${API_URL}/permission/user`;

export function getUserByToken(loginRes: any) {
  return axios.get<any>(GET_USER_BY_ACCESSTOKEN_URL, {
    params: {},
    headers: {
      Authorization: loginRes.token,
      loggedInUserId: 1,
    },
  });
}

export function getUserPermission(token: string) {
  return axios.get<any>(GET_USER_ACCESS_URL);
}




// export function getUserPermission(token: any) {
//   return axios.get<any>(GET_USER_ACCESS_URL, {
//     params: {},
//     headers: {
//       Authorization: token,
//       loggedInUserId: 1,
//     },
//   });
// }


